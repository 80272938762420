<template>
  <v-container fluid>
    <CustomerTopBar :global="true" />
    <v-divider
      class="mt-4"
      style="border-color: rgba(17, 42, 70, 0.14) !important"
    />
    <v-row>
      <v-col cols="12" lg="12" md="12">
        <div class="titles mt-6">Preferences</div>
        <v-card outlined class="mb-12" :style="cardStyle">
          <v-card-text>
            <v-row>
              <v-col lg="4" md="6" sm="12" cols="12">
                <label for=""> Maximum Tickets Per User </label>
                <v-text-field
                  label=""
                  required
                  v-model="threshold"
                  outlined
                  background-color="#fff"
                  :rules="[
                    (v) => !!v || 'Maximum Tickets Per User is required',
                    (v) =>
                      !isNaN(v) || 'Maximum Tickets Per User must be Number',
                  ]"
                  hide-details="auto"
                  dense
                  class="q-text-field shadow-0"
                ></v-text-field>
              </v-col>
              <v-col
                lg="4"
                md="6"
                sm="12"
                cols="12"
                v-if="
                  checkReadPermission($modules.clients.advanced_settings.slug)
                "
              >
                <label>Customer Age selection:</label>
                <v-radio-group
                  v-model="enableAgeRange"
                  row
                  class="pos-cf-action-heading"
                  label=""
                >
                  <v-radio label="DOB" :value="0"></v-radio>
                  <v-radio label="Age Group" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
                class="mt-6"
                v-if="checkWritePermission(this.$modules.vcp.management.slug)"
              >
                <v-checkbox
                  class="mt-0 pt-0"
                  v-model="enable_anonymous_access"
                  label="Enable Anonymous Customer Access"
                  @change="changeAnonymousStatus"
                ></v-checkbox>
              </v-col>

              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
                class="mt-6"
                v-if="
                  checkWritePermission($modules.clients.restrict_app_login.slug)
                "
              >
                <v-checkbox
                  class="mt-0 pt-0"
                  v-model="enable_device_authorization_approval"
                  label="Enable Device Authorization Approval for App login"
                  @change="changeDeviceAuthorizationApprovalStatus"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2" md="2" ml="5"></v-col>

      <v-spacer></v-spacer>
      <div style="float: right">
        <v-btn class="white--text blue-color" @click="openCategoriesModal">
          <v-icon dark>mdi-cog</v-icon>
          Categories
        </v-btn>
      </div>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12" lg="12" md="12">
        <v-form ref="cform">
          <div class="titles">Tags</div>
          <v-row>
            <v-col
              lg="4"
              md="6"
              sm="12"
              cols="12"
              v-for="(code, cIndex) in colorCodes"
              :key="`index${cIndex}`"
              style="position: relative"
            >
              <v-card class="shadow-2">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="12" class="pb-0">
                      <label for=""> Tag name </label>
                      <v-text-field
                        outlined
                        background-color="#fff"
                        light
                        v-model="code.name"
                        dense
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        required
                        :rules="[(v) => !!v || 'Tag name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <label for=""> Category </label>
                      <v-select
                        v-model="code.category_id"
                        :items="tagCategories"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        outlined
                        dense
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        required
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <label for=""> Color Code </label>
                      <v-text-field
                        outlined
                        v-model="code.color_code"
                        background-color="#fff"
                        light
                        dense
                        hide-details="auto"
                        class="q-text-field shadow-0 color-picker"
                        required
                        :rules="[(v) => !!v || 'Color code is required']"
                      >
                        <template v-slot:append>
                          <v-menu
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <div :style="swatchStyle(cIndex)" v-on="on" />
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker
                                  v-model="code.color_code"
                                  flat
                                  :swatches="swatches"
                                  show-swatches
                                />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col md="4" class="pl-0">
                      <v-switch
                        class="mx-4 my-0"
                        dense
                        hide-details="auto"
                        :false-value="14"
                        :true-value="1"
                        label="Active"
                        required
                        v-model="code.status_id"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-tooltip bottom v-if="colorCodes.length > 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red"
                    dark
                    elevation="0"
                    fab
                    v-bind="attrs"
                    x-small
                    absolute
                    top
                    style="top: -5px"
                    right
                    @click="deleteCode(cIndex)"
                    v-on="on"
                  >
                    <DeleteIcon />
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </v-col>
          </v-row>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="blue-color"
                  fab
                  x-small
                  dark
                  @click="addColorCode()"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add new code
            </v-tooltip>
          </div>
          <div style="float: right">
            <v-btn
              class="white--text blue-color"
              @click="saveColorCodes($event)"
              >Update
            </v-btn>
          </div>
        </v-form>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-dialog v-model="categoriesModal" persistent max-width="600">
      <v-card class="">
        <v-card-title class="headline">
          <h3 class="success-heading">Tag Categories</h3>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
              v-for="(category, index) in categories"
              :key="index"
            >
              <v-row class="ma-0">
                <v-col cols="10">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    hide-details
                    v-model="category.name"
                    label="Category name*"
                    :rules="[(v) => !!v || 'Category name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="del_btn"
                        color="red"
                        @click="deleteCategory(index)"
                        x-small
                        dark
                        fab
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="blue-color"
                  fab
                  x-small
                  dark
                  @click="addCategory()"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add new tag
            </v-tooltip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="closeCategoriesModal"
            >Close
          </v-btn>

          <v-btn
            color=" darken-1"
            class="ma-2 white--text teal-color"
            @click="saveCategories"
            text
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import DeleteIcon from "@/assets/images/retail/delete-bg-icon.svg";
import CustomerTopBar from "@/views/Clients/CustomerTopBar.vue";

export default {
  components: {CustomerTopBar, DeleteIcon },
  computed: {
    tagCategories() {
      return this.$store.getters.getTagCategories.data;
    },
  },
  data() {
    return {
      categoriesModal: false,
      categories: [
        {
          name: "",
        },
      ],
      deleted_categories: [],
      threshold: 10,
      enableAgeRange: false,
      btnShow: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      colorCodes: [
        {
          name: null,
          status_id: 1,
          category_id: null,
          color_code: "#00AAAAFF",
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      deleted_codes: [],
      enable_anonymous_access: false,
      enable_device_authorization_approval: false,
    };
  },
  mounted() {
    if (this.$store.getters.getTagCategories.status == false) {
      this.$store.dispatch("loadTagCategories");
    }
    setTimeout(() => {
      this.btnShow = true;
      this.loadConfigurations();
    }, 10);
  },
  methods: {
    deleteCategory(cIndex) {
      let pkg = this.categories[cIndex];
      if (pkg.name == null && pkg.id == null) {
        this.categories.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this Category?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "category",
        };
      }
    },
    addCategory() {
      this.categories.push({
        name: "",
      });
    },
    closeCategoriesModal() {
      this.categoriesModal = false;
      this.categories = [
        {
          name: "",
        },
      ];
    },
    openCategoriesModal() {
      this.$http
        .get(`venues/customers/tags/categories`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.categoriesModal = true;
            if (data.length) {
              this.categories = data;
            }
            this.deleted_categories = [];
            this.$forceUpdate();
            this.$refs.cform.resetValidation();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    saveCategories() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = {
        categories: this.categories,
      };
      if (this.deleted_categories.length) {
        data.deleted_categories = this.deleted_categories;
      }
      this.showLoader();
      this.$http
        .post(`venues/customers/tags/categories`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Categories saved");
            this.$store.dispatch("loadTagCategories");
            this.closeCategoriesModal();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    loadConfigurations() {
      this.getColorCodes();
      this.getVenueDetails();
    },
    addColorCode() {
      this.colorCodes.push({
        name: null,
        status_id: 1,
        category_id: null,
        color_code: "#00AAAAFF",
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    swatchStyle(cIndex) {
      const { color_code, menu } = this.colorCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    getColorCodes() {
      this.$http
        .get(`venues/customers/tags`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            const config = response.data.config;
            if (data.length) {
              this.colorCodes = data;
            } else {
              this.colorCodes = [
                {
                  name: null,
                  status_id: 1,
                  category_id: null,
                  color_code: "#00AAAAFF",
                },
              ];
            }
            if (config) {
              this.threshold = config.customer_threshold;
              this.enableAgeRange = config.enable_age_range;
            }
            this.deleted_codes = [];
            this.$forceUpdate();
            if (this.$refs.cform) {
              this.$refs.cform.resetValidation();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getVenueDetails() {
      this.$http
        .get(`venues/profile`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.enable_anonymous_access = data.enable_anonymous_access
              ? true
              : false;
            this.enable_device_authorization_approval =
              data.enable_device_authorization_approval ? true : false;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    saveColorCodes() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = {
        color_codes: this.colorCodes,
        threshold: this.threshold,
        enableAgeRange: this.enableAgeRange,
      };
      if (this.deleted_codes.length) {
        data.deleted_codes = this.deleted_codes;
      }
      this.showLoader();
      this.$http
        .post(`venues/customers/tags`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Tags saved");
            this.getColorCodes();
            this.hideLoader();
            this.$store.dispatch("LoadCustomerAgeRangeConfiguration");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteCode(cIndex) {
      let pkg = this.colorCodes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this Tag?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "color_code",
        };
      }
    },
    changeAnonymousStatus(id) {
      let msg =
        "This will toggle Anonymous Customer Access Status. Confirm the operation by clicking 'Yes'";
      this.confirmModel = {
        id: id ? 1 : 0,
        title: `Do you want to change Anonymous Customer Access Status?`,
        description: msg,
        type: "anonymous",
      };
    },
    changeDeviceAuthorizationApprovalStatus(id) {
      let msg =
        "This will toggle Device Authorization Approval Status. Confirm the operation by clicking 'Yes'";
      this.confirmModel = {
        id: id ? 1 : 0,
        title: `Do you want to change Device Authorization Approval Status?`,
        description: msg,
        type: "device-authorization-approval",
      };
    },
    confirmActions(data) {
      if (data.type == "color_code") {
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        this.$forceUpdate();
      }
      if (data.type == "category") {
        let index = data.id;
        if (this.categories[index].id != null) {
          this.deleted_categories.push(this.categories[index].id);
        }
        this.categories.splice(index, 1);
        this.$forceUpdate();
      }
      if (data.type == "anonymous") {
        this.changeAnonymousCustomerAccessStatus();
      } else if (data.type == "device-authorization-approval") {
        this.changeAppDeviceAuthorizationApprovalStatus();
      }
      this.confirmModel.id = null;
    },
    changeAnonymousCustomerAccessStatus() {
      this.$http
        .get(`venues/customers/change-anonymous-access-status-change`)
        .then((response) => {
          if (response.status == 200) {
            this.enable_anonymous_access =
              response.data.data.enable_anonymous_access;
            this.showSuccess(
              `Anonymous customer access status Successfully changed.`
            );
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeAppDeviceAuthorizationApprovalStatus() {
      this.$http
        .get(
          `venues/customers/change-app-device-authorization-approval-status-change`
        )
        .then((response) => {
          if (response.status == 200) {
            this.enable_device_authorization_approval =
              response.data.data.enable_device_authorization_approval;
            this.showSuccess(
              `Device Authorization Approval status Successfully changed.`
            );
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotToCustomers() {
      this.$router.push({
        name: "Customers",
      });
    },
  },
};
</script>
<style>
.color-picker .v-input__append-inner {
  margin-top: 6px !important;
}
</style>
<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.personal-trainer_ic {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  background-position: center center;
  padding: 10px;
  background-size: contain;
}

.pos-cf-action-heading {
  margin-top: 10px;
  color: #112a46;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 0 0 10px;
}
</style>
